<template>
  <!-- <div v-if="this.row[this.header] != 0">{{ minutes }} min {{ seconds }}s</div> -->
  <div v-if="this.row[this.header] !== ''">{{ formatedTime }}</div>
  <div v-else>...</div>
</template>

<script>
export default {
  props: ["header", "row", "result"],
  computed: {
    formatedTime() {
      return new Date(this.row[this.header] * 1000).toISOString().substr(11, 8);
    },
    // minutes() {
    //   return Math.floor(this.row[this.header] / 60);
    // },
    // seconds() {
    //   return this.row[this.header] - this.minutes * 60;
    // },
  },
};
</script>

<style scoped>
</style>